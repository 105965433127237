import { Dispatch, SetStateAction } from 'react'

/** Arrow Down SVG icon */
const arrowDownSvg = 'M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z'

const regions = [
  { name: 'us-west-1', value: 'us-west-1' },
  { name: 'us-west-2', value: 'us-west-2' },
  { name: 'us-east-1', value: 'us-east-1' },
  { name: 'eu-central-1', value: 'eu-central-1' },
  { name: 'ca-central-1', value: 'ca-central-1' },
  { name: 'ap-southeast-2', value: 'ap-southeast-2' },
]

interface RegionComponentProps {
  setRegionHandler: Dispatch<SetStateAction<string>>
}

const RegionComponent = ({ setRegionHandler }: RegionComponentProps): JSX.Element => {
  return (
    <div className="inline-block relative">
      <select
        id={'regionComponent'}
        onChange={(e) => setRegionHandler(e.target.value)}
        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
      >
        {regions.map((x) => {
          return (
            <option key={x.name} value={x.value}>
              {x.name}
            </option>
          )
        })}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d={arrowDownSvg} />
        </svg>
      </div>
    </div>
  )
}

export { RegionComponent }
