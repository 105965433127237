import { FunctionComponent, useEffect, useState, createRef } from 'react'

import Cjs from '@meazure/copernicusjs'

import { MEDIA_SERVER_URL, VIDEO_SERVICE_URL } from '../config'
import { ProctorVideoComponent } from '../components/ProctorVideoComponent'
import { ProctorCombinedVideoComponent } from '../components/ProctorCombinedVideoComponent'
import { ProctorCombinedWithSecondCameraVideoComponent } from '../components/ProctorCombinedWithSecondCameraVideoComponent'
import { AudioChatComponent } from '../components/AudioChatComponent'
import { buildIceServers, useUnload } from '../utils'
import { v4 as uuid } from 'uuid'
import './ProctorSession.css'
import { RegionComponent } from '../components/RegionComponent'
import { VideoLayoutComponent } from '../components/VideoLayoutComponent'

/** Filter devices based on device type, and ignore "default" devices.
 *
 * User's should be explicit about inputs/outputs.
 */
const filterDevices = (devices: InputDeviceInfo[], kind: string): InputDeviceInfo[] => {
  return devices.filter((x) => x.kind === kind && x.label.toLowerCase() !== 'default')
}

/** Input device types */
enum InputDevices {
  MICROPHONE = 'audioinput',
}

const ProctorPage: FunctionComponent = (): JSX.Element => {
  // Session Details
  const [fulfillmentId, setFulfillmentId] = useState<string>('')
  const [userId, setUserId] = useState<string>('')
  const [role, setRole] = useState<string>('proctor')

  // Auth stuff...
  const [token, setToken] = useState<string>('')

  // Copernicus Instance
  const [copernicus, setCopernicus] = useState<Cjs>(null)
  const [isConnectedToCopernicus, setIsConnectedToCopernicus] = useState<boolean>(false)

  // Reference for Camera, Screen and AudioChat Players
  const cameraVideoRef = createRef<HTMLVideoElement>()
  const videoCombinedPlayerRef = createRef<HTMLVideoElement>()
  const videoCombinedWithSecondCameraPlayerRef = createRef<HTMLVideoElement>()
  const screenVideoRef = createRef<HTMLVideoElement>()
  const audioOutputRef = createRef<HTMLAudioElement>()

  // Media Device Information
  const [devices, setDevices] = useState<InputDeviceInfo[]>([])
  const [currentMicrophoneDevice, setCurrentMicrophoneDevice] = useState<string>('')
  const [userMediaConsent, setUserMediaConsent] = useState<boolean>(false)

  const [videoLayout, setVideoLayout] = useState<string>('combined')
  const [region, setRegion] = useState<string>('us-west-1')

  // Cleanup anything...
  useUnload((e) => {
    e.preventDefault()
    copernicus?.close()
  })

  const getMicrophonePermissions = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        stream.getTracks().map((x) => x.stop()) // If you do not do this, then a stream will still be capturing data.
        return navigator.mediaDevices.enumerateDevices()
      })
      .then((devices) => {
        setDevices(devices)
        console.info('User has agreed to share microphone.')
        setUserMediaConsent(true)
      })
      .catch((e) => {
        console.error(e)
        console.info('User has denied sharing microphone.')
      })
  }

  const getSessionParams = () => {
    const params = new URLSearchParams(window.location.search)
    setFulfillmentId(params.get('fulfillment_id') || '')
    setUserId(params.get('user_id') || '')
    setRole(params.get('role') || '')

    // Expect: http://localhost:3000/#token=foobar
    if (window.location.hash.includes('token')) {
      setToken(window.location.hash.split('token')[1].split('=')[1])
    }
  }

  const start = () => {
    connectToCopernicus()
  }

  const stop = () => {
    disconnectFromCopernicus()
  }

  function genericDisconnectHandlers(/** @type {any} */ evt) {
    console.log('disconnect received: ', evt)
  }

  function genericConnectionHandlers(/** @type {any} */ evt) {
    console.log('connection received: ', evt)
  }

  function genericErrorHandlers(/** @type {any} */ evt) {
    console.log('error received: ', evt)
  }

  // Connect to Manager
  const connectToCopernicus = async () => {
    console.debug('Connecting to Copernicus...')
    getSessionParams()

    const devices = {
      audioInput: currentMicrophoneDevice,
    }

    const domElements = {
      webcam:
        videoLayout === 'legacy'
          ? cameraVideoRef.current
          : videoLayout === 'combined'
          ? videoCombinedPlayerRef.current
          : videoCombinedWithSecondCameraPlayerRef.current,
      screen: screenVideoRef.current,
      audioOutput: audioOutputRef.current,
    }
    const settings = {
      videoLayout: videoLayout,
      combinedStreamMaxBandwidth: 1000000,
      combinedStreamFrameRate: 10,
      secondCameraMaxBandwidth: 1500000,
    }
    const iceServers = buildIceServers()
    const cjsInstance = new Cjs(
      {
        userID: userId,
        userUUID: uuid(),
        role,
        exam: fulfillmentId,
        domElements,
        mediaServerUrl: new URL(MEDIA_SERVER_URL),
        videoServiceUrl: new URL(VIDEO_SERVICE_URL),
        iceServers,
        devices,
        videoLayout: settings.videoLayout,
        region: region,
      },
      {
        onError: genericErrorHandlers,
        onConnect: genericConnectionHandlers,
        onDisconnect: genericDisconnectHandlers,
      }
    )

    const isCjsInstanceInitialized = await cjsInstance.initialize()
    if (isCjsInstanceInitialized) {
      try {
        cjsInstance.startLiveStream(settings)
        setCopernicus(cjsInstance)
        setIsConnectedToCopernicus(true)
        console.info('[Copernicus] Connection established')
      } catch (e) {
        console.error(e)
        console.info('[Copernicus] Start failed')
      }
    } else {
      console.error('[CJS] cjsInstance not initialized')
    }
  }
  const disconnectFromCopernicus = () => {
    copernicus.close()
    setCopernicus(null)
    setIsConnectedToCopernicus(false)
  }

  // Get device info on page load
  useEffect(() => {
    getSessionParams()
    getMicrophonePermissions()
  }, [])

  // Set the current device to the first input received
  useEffect(() => {
    if (devices.length > 0) {
      setCurrentMicrophoneDevice(filterDevices(devices, InputDevices.MICROPHONE)[0]?.deviceId || '')
    }
  }, [devices])

  // Logging
  useEffect(() => {
    console.debug(
      JSON.stringify({
        fulfillmentId,
        userId,
        token,
        currentMicrophoneDevice,
        userMediaConsent,
        isConnectedToCopernicus,
      })
    )
  }, [fulfillmentId, userId, token, currentMicrophoneDevice, userMediaConsent, isConnectedToCopernicus])

  return (
    <>
      <div className="grid grid-cols-2 mt-12 mb-6">
        <div className="mx-auto" style={isConnectedToCopernicus ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
          <h2 className="text-2xl font-bold mt-6">Layout</h2>
          <VideoLayoutComponent setLayoutHandler={setVideoLayout} />
        </div>
        <div className="mx-auto" style={isConnectedToCopernicus ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
          <h2 className="text-2xl font-bold mt-6">Region</h2>
          <RegionComponent setRegionHandler={setRegion} />
        </div>
      </div>

      <div hidden={isConnectedToCopernicus}>
        <div className="mt-4 text-center">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={start}>
            Start
          </button>
        </div>
      </div>
      <div hidden={!isConnectedToCopernicus}>
        <div className="mt-4 text-center">
          <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={stop}>
            Stop
          </button>
        </div>
      </div>
      <div hidden={!isConnectedToCopernicus}>
        <br />
        <ProctorVideoComponent videoRef={cameraVideoRef} hidden={videoLayout != 'legacy'} />
        <br />
        <ProctorVideoComponent videoRef={screenVideoRef} hidden={videoLayout != 'legacy'} />
        <ProctorCombinedVideoComponent videoRef={videoCombinedPlayerRef} hidden={videoLayout != 'combined'} />
        <ProctorCombinedWithSecondCameraVideoComponent
          videoRef={videoCombinedWithSecondCameraPlayerRef}
          hidden={videoLayout != 'combinedWithSecondCamera'}
        />
      </div>
      <AudioChatComponent audioOutputRef={audioOutputRef} />
    </>
  )
}

export { ProctorPage }
